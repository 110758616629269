/* NORMAL PAGE SIZE */
.main-content {
  min-width: 300px;
  padding: 30px;
  max-width: 800px;
  display: block;
  margin: 0 auto;
}
/* 
.  {
  min-width: 300px;
  padding: 30px;
  max-width: 100vw;
  display: block;
  margin-left: 5rem;
  min-height: 100vh;
  height: 100%;
} */

.page-background {
  background: var(--gradient, white);
}

/* TEXT PAGE: every page with text content as the most important element */
.text-page {
  min-width: 300px;
  padding: 30px;
  max-width: 800px;
  display: block;
  margin: 0 auto;
}

/* FORM PAGE: every page with Typeform as main content */
.form-page {
  padding: 30px;
  max-width: 100%;
  height: 100%;
  display: block;
  margin-top: 0;
}
