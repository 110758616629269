@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl;
    @apply text-gray-700 dark:text-white;
    @apply text-center;
    @apply mb-2;
    @apply font-semibold;
  }
  h2 {
    @apply text-xl;
    @apply mb-2;
    @apply text-center;
    @apply text-gray-700 dark:text-white;
    @apply font-semibold;
  }

  h3 {
    @apply text-lg;
    @apply mb-2;
    @apply text-center;
    @apply text-gray-700 dark:text-white;
    @apply font-semibold;
  }

  a {
    @apply underline;
  }

  p {
    @apply text-base;
    @apply leading-loose;
  }

  .dashboard {
    @apply p-2 pb-36 mb-20 pt-3 sm:pt-10 sm:pb-16 sm:mb-0 sm:p-8 sm:block sm:ml-24 min-h-screen sm:h-full overflow-x-hidden overflow-y-auto dark:text-white;
  }

  .dashboard-collapsed {
    @apply p-2 pb-36 mb-20 pt-3 sm:pt-10 sm:pb-16 sm:mb-0 sm:p-8 sm:block sm:ml-14 min-h-screen sm:h-full overflow-x-hidden overflow-y-auto ;
  }

  .homepage {
    @apply  min-h-screen  h-full ;
  }

  .paper-background {
    @apply h-screen fixed inset-0 -z-30 h-full w-full bg-white bg-[linear-gradient(to_right,#f0f0f0_1px,transparent_1px),linear-gradient(to_bottom,#f0f0f0_1px,transparent_1px)] bg-[size:6rem_4rem];
  }

  .dotted-background {
    @apply fixed h-screen inset-0 -z-30 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px];
  }

  .dotted-background-div {
    @apply inset-0 -z-10 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]
  }


  .muted-text {
    @apply text-gray-600;
  }

  .persian-green-gradient {
    @apply bg-gradient-to-r from-persian-green to-darker-persian-green;
  }
  
  .charcoal-gradient {
    @apply bg-gradient-to-r from-charcoal to-darker-charcoal;
  }

  .burnt-sienna-gradient {
    @apply bg-gradient-to-r from-burnt-sienna to-darker-burnt-sienna;
  }

  .gray-background-gradient {
    @apply transition ease-in-out bg-gradient-to-r from-gray-100 to-gray-200;
  }

  .dark-mode-background-color {
    @apply bg-gray-800;
  }

  .darker-dark-mode-background-color {
    @apply bg-gray-900;
  }

  .dark-mode-background-gradient {
    @apply transition ease-in-out bg-gradient-to-r dark:from-black dark:to-gray-800;
  }

  .dark-mode-muted-text {
    @apply dark:text-gray-400;
  }

}
