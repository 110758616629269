.button {
  border: none;
  color: white;
  padding: 15px 32px;
  border-radius: 5px;
  box-shadow: var(--black-box-shadow);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  background: var(--persian-green);
}

.button:focus {
  outline: none;
}

.icon {
  font-size: 1.5em;
}

.green {
  background: var(--persian-green);
}

.red {
  background: var(--burnt-sienna);
}

.orange {
  background: var(--sandy-brown);
}

.charcoal {
  background: var(--charcoal);
}

.blue {
  background: var(--color3);
}
