.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 300px));
  gap: 2rem;
  justify-content: center;
  margin: 2rem;
}

.grid-item {
  display: grid;
  place-items: center;
  width: 100%;
  position: relative;
}

.grid-item::before {
  content: "";
  padding-top: 100%;
  display: block;
}

.grid-content {
  position: absolute;
  height: 100%;
  width: 100%;
}

.modal {
  position: fixed;
  display: none;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.active {
  display: block;
}

.modal-window {
  width: 70vw;
  left: 50%;
  top: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  position: fixed;
  box-shadow: var(--black-box-shadow);
  background: var(--blue-gradient);
  color: white;
  border-radius: 10px;
}

.modal-content {
  display: block;
  padding: 30px;
  text-align: center;
}

.modal-input-field,
.modal-button-group {
  margin: 15px;
}

.modal-input-field input[type="text"] {
  width: 50%;
  height: 50px;
  font-size: 1.9em;
  text-align: center;
}

.modal-button-group {
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
